const TuTranslation = {
  main: {
    Home: "Ana Sayfa",
    AboutUs: "Hakkımızda",
    ExursionsFromHurghada: "Hurghada'dan Geziler",
    Hotels: "Oteller",
    Shopping: "Alışveriş",
    Blog: "Blog",
    ContactUs: "Bize Ulaşın",
    CallUs: "Bizi Ara",
    Email: "E-posta",
    ReadMore: "Daha Fazla Oku",
  },
  Hurghada: {
    Bookyourtriponline: "Geziyi Online Rezerve Et",
    NameFamilyName: "Ad / Soyad",
    Email: "E-posta",
    Phone: "Telefon (Viber, WhatsApp veya Telegram)",
    Date: "Tarih",
    NumberOfPeople: "Kişi Sayısı",
    Adults: "Yetişkinler",
    Children: "Çocuklar (5-11 yaş)",
    Infant: "Bebekler (0-4 yaş)",
    Message: "Mesaj",
    BookNow: "Şimdi Rezervasyon Yap",
  },
  Hotel: {
    Stars: "Yıldızlar",
  },
  ContactUs: {
    Address: "Adres",
    Email: "E-posta",
    Phone: "Telefon",
    Name: "Ad",
    Subject: "Konu",
    Message: "Mesaj",
    Send: "Gönder",
  },
};

export type TranslationObjectType = typeof TuTranslation;

export default TuTranslation;
