const EnTranslation = {
  main: {
    Home: "Home",
    AboutUs: "About Us",
    ExursionsFromHurghada: "Exursions From Hurghada",
    Hotels: "Hotels",
    Shopping: "Shopping",
    Blog: "Blog",
    ContactUs: "Contact Us",
    CallUs: "Call Us",
    Email: "Email",
    ReadMore: "Read More",
  },
  Hurghada: {
    Bookyourtriponline: "Book your trip online",
    NameFamilyName: "Name / Family Name",
    Email: "Email",
    Phone: "Phone (Viber or whatsapp or telegram)",
    Date: "Date",
    NumberOfPeople: "Number Of People",
    Adults: "Adults",
    Children: "Children (ages 5-11)",
    Infant: "Infant (ages 0-4)",
    Message: "Message",
    BookNow: "Book Now",
  },
  Hotel: {
    Stars: "Stars",
    ViewVideo: "View Video",
  },
  ContactUs: {
    Address: "Address",
    Email: "Email",
    Phone: "Phone",
    Name: "Name",
    Subject: "Subject",
    Message: "Message",
    Send: "Send",
  },
};
export type TranslationObjectType = typeof EnTranslation;

export default EnTranslation;
