import FristNavbar from "../FristNavbar";
import SecoundNavbar from "../SecondNavbar";

function Navbar() {
  return (
    <>
      <FristNavbar />
      <SecoundNavbar />
    </>
  );
}
export default Navbar;
