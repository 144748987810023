const ArTranslation = {
  main: {
    Home: "الرئيسية",
    AboutUs: "معلومات عنا",
    ExursionsFromHurghada: "رحلات من الغردقة",
    Hotels: "الفنادق",
    Shopping: "التسوق",
    Blog: "المدوّنة",
    ContactUs: "اتصل بنا",
    CallUs: "اتصل بنا",
    Email: "البريد الإلكتروني",
    ReadMore: "اقرأ المزيد",
  },
  Hurghada: {
    Bookyourtriponline: "احجز رحلتك عبر الإنترنت",
    NameFamilyName: "الاسم / اسم العائلة",
    Email: "البريد الإلكتروني",
    Phone: "الهاتف (فايبر أو واتساب أو تلغرام)",
    Date: "التاريخ",
    NumberOfPeople: "عدد الأشخاص",
    Adults: "الكبار",
    Children: "الأطفال (من 5 إلى 11 سنة)",
    Infant: "الرضّع (من 0 إلى 4 سنوات)",
    Message: "الرسالة",
    BookNow: "احجز الآن",
  },
  Hotel: {
    Stars: "النجوم",
    ViewVideo: "مشاهدة الفيديو",
  },
  ContactUs: {
    Address: "العنوان",
    Email: "البريد الإلكتروني",
    Phone: "الهاتف",
    Name: "الاسم",
    Subject: "الموضوع",
    Message: "الرسالة",
    Send: "إرسال",
  },
};

export type TranslationObjectType = typeof ArTranslation;

export default ArTranslation;
