const GeTranslation = {
  main: {
    Home: "Startseite",
    AboutUs: "Über uns",
    ExursionsFromHurghada: "Ausflüge ab Hurghada",
    Hotels: "Hotels",
    Shopping: "Einkaufen",
    Blog: "Blog",
    ContactUs: "Kontaktiere uns",
    CallUs: "Ruf uns an",
    Email: "E-Mail",
    ReadMore: "Mehr lesen",
  },
  Hurghada: {
    Bookyourtriponline: "Buchen Sie Ihre Reise online",
    NameFamilyName: "Name / Nachname",
    Email: "E-Mail",
    Phone: "Telefon (Viber, WhatsApp oder Telegram)",
    Date: "Datum",
    NumberOfPeople: "Anzahl der Personen",
    Adults: "Erwachsene",
    Children: "Kinder (5-11 Jahre)",
    Infant: "Kleinkind (0-4 Jahre)",
    Message: "Nachricht",
    BookNow: "Jetzt buchen",
  },
  Hotel: {
    Stars: "Sterne",
  },
  ContactUs: {
    Address: "Adresse",
    Email: "E-Mail",
    Phone: "Telefon",
    Name: "Name",
    Subject: "Betreff",
    Message: "Nachricht",
    Send: "Senden",
  },
};
export type TranslationObjectType = typeof GeTranslation;

export default GeTranslation;
