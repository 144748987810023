const RuTranslation = {
  main: {
    Home: "Главная",
    AboutUs: "О нас",
    ExursionsFromHurghada: "Экскурсии из Хургады",
    Hotels: "Отели",
    Shopping: "Шопинг",
    Blog: "Блог",
    ContactUs: "Свяжитесь с нами",
    CallUs: "Позвоните нам",
    Email: "Эл. почта",
    ReadMore: "Читать далее",
  },
  Hurghada: {
    Bookyourtriponline: "Забронируйте свою поездку онлайн",
    NameFamilyName: "Имя / Фамилия",
    Email: "Эл. почта",
    Phone: "Телефон (Viber, WhatsApp или Telegram)",
    Date: "Дата",
    NumberOfPeople: "Количество человек",
    Adults: "Взрослые",
    Children: "Дети (5-11 лет)",
    Infant: "Младенцы (0-4 года)",
    Message: "Сообщение",
    BookNow: "Забронировать сейчас",
  },
  Hotel: {
    Stars: "Звезды",
  },
  ContactUs: {
    Address: "Адрес",
    Email: "Эл. почта",
    Phone: "Телефон",
    Name: "Имя",
    Subject: "Тема",
    Message: "Сообщение",
    Send: "Отправить",
  },
};
export type TranslationObjectType = typeof RuTranslation;

export default RuTranslation;
