const PoTranslation = {
  main: {
    Home: "Strona główna",
    AboutUs: "O nas",
    ExursionsFromHurghada: "Wycieczki z Hurghady",
    Hotels: "Hotele",
    Shopping: "Zakupy",
    Blog: "Blog",
    ContactUs: "Kontakt",
    CallUs: "Zadzwoń do nas",
    Email: "E-mail",
    ReadMore: "Czytaj więcej",
  },
  Hurghada: {
    Bookyourtriponline: "Zarezerwuj swój wyjazd online",
    NameFamilyName: "Imię / Nazwisko",
    Email: "E-mail",
    Phone: "Telefon (Viber, WhatsApp lub Telegram)",
    Date: "Data",
    NumberOfPeople: "Liczba osób",
    Adults: "Dorośli",
    Children: "Dzieci (5-11 lat)",
    Infant: "Niemowlęta (0-4 lata)",
    Message: "Wiadomość",
    BookNow: "Zarezerwuj teraz",
  },
  Hotel: {
    Stars: "Gwiazdki",
  },
  ContactUs: {
    Address: "Adres",
    Email: "E-mail",
    Phone: "Telefon",
    Name: "Imię",
    Subject: "Temat",
    Message: "Wiadomość",
    Send: "Wyślij",
  },
};
export type TranslationObjectType = typeof PoTranslation;

export default PoTranslation;
