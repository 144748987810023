import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import CloseIcon from "@mui/icons-material/Close";
import { useContext } from "react";
import { homeContext } from "pages/HomeContext";
import { imgPath } from "methods/img";

function FixedIcon() {
  const { homeData } = useContext(homeContext);
  const actions = [
    {
      icon: (
        <img
          src={imgPath(homeData?.contactus.image_whatsup)}
          alt="Custom Icon"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
          }}
        />
      ),
      name: `${homeData?.contactus.name_whatsup}`,
      bgcolor: "#25d366",
      link: `${homeData?.contactus.whatsup}`,
    },
    {
      icon: (
        <img
          src={imgPath(homeData?.contactus.image_telegram)}
          alt="Custom Icon"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
          }}
        />
      ),
      name: `${homeData?.contactus.name_telegram}`,
      bgcolor: "#279dd8",
      link: `${homeData?.contactus.telegram}`,
    },
    {
      icon: (
        <img
          src={imgPath(homeData?.contactus.image_viber)}
          alt="Custom Icon"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
          }}
        />
      ),
      name: `${homeData?.contactus.name_viber}`,
      bgcolor: "#784F99",
      link: `${homeData?.contactus.viber}`,
    },
    {
      icon: (
        <img
          src={imgPath(homeData?.contactus.image_phone)}
          alt="Custom Icon"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
          }}
        />
      ),
      name: `${homeData?.contactus.name_phone}`,
      bgcolor: "green",
      link: `${homeData?.contactus.phone}`,
    },
  ];
  return (
    <Box
      sx={{
        height: 320,
        transform: "translateZ(0px)",
        flexGrow: 1,
        position: "fixed",
        bottom: "5px",
        right: "10px",
        zIndex: "10000000000000000000000",
      }}
    >
      <SpeedDial
        ariaLabel="SpeedDial openIcon example"
        sx={{ position: "absolute", bottom: 10, right: 0 }}
        icon={
          <SpeedDialIcon
            icon={<MarkUnreadChatAltIcon />}
            openIcon={<CloseIcon />}
          />
        }
      >
        {actions.map((action, index) => (
          <SpeedDialAction
            key={index}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => {
              window.open(`${action.link}`, "_blank");
            }}
            sx={{
              width: "60px",
              height: "60px",
              // bgcolor: action.bgcolor,
              // "&:hover": {
              //   background: action.bgcolor,
              // },
            }}
          />
        ))}
      </SpeedDial>
    </Box>
  );
}

export default FixedIcon;
